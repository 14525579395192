import React from 'react';

//components import
import Header from './Header';
import HowWorks from './HowWorks';
import Footer from '../../components/Footer';
import Workflow from './WorkflowSection';
import Services from './Services';
import FormSection from './FormSection';
import FaqSection from './FaqSection';
import ExpertiseSection from './ExpertiseSection';

import { useFormSectionScroll } from '../../ScrollContext';
import BlogSection from './BlogSection';
import AboutSection from './AboutSection';
import TestimonialSection from './TestimonialSection';

const HomePage = () => {
    const { formSectionRef } = useFormSectionScroll();

    return (
        <div className="home_page">
            <Header />
            <AboutSection />
            <Workflow />
            <BlogSection />
            <Services />
            <ExpertiseSection />
            <HowWorks />
            <TestimonialSection />
            <FormSection forwardedRef={formSectionRef} />
            <FaqSection />
            <Footer />
        </div>
    );
};

export default HomePage;
