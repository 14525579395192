import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
    Box,
    Paper,
    Typography,
    Accordion,
    Container,
    Stack,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CustomButton from '../../components/CustomButton';

const FaqData = [
    {
        question: 'What happens after I submit my project query?',
        answer: 'Once you submit your query, our team begins an immediate review. You can expect a response within 24 hours to discuss the next steps.',
    },
    {
        question: 'What technologies do you use to develop products?',
        answer: "Our expertise spans React, Next.js for frontend development, Flask, Django, Express for backend solutions, and React Native and Swift for mobile apps. We're proficient in Azure and AWS for cloud services. While Next.js and Flask are our go-to for MVPs, we're flexible to adapt to any tech stack per your project's needs.",
    },
    {
        question: 'Do you handle NDAs and confidentiality agreements?',
        answer: 'Absolutely. We prioritize your privacy and security by signing NDAs to keep all project information confidential.',
    },
    {
        question: 'How much does it cost to develop a product?',
        answer: 'Costs vary based on project scope and complexity. For MVPs, we aim to keep development under $15,000. We provide a detailed estimate for more significant projects reflecting the intricacy and time requirements, all within our transparent pricing policy.',
    },
    {
        question: 'What support do you offer post-product completion?',
        answer: "Beyond delivering your final product, we offer 14 days of support to address immediate concerns. We're also available for ongoing assistance and future project collaborations to ensure your continued success.",
    },
];

const FaqSection = () => {
    return (
        <div className="faq-section">
            <Box sx={{ mt: 5 }}>
                <Paper
                    elevation={0}
                    sx={{ background: '#F6F0E9', p: 5, borderRadius: 10, m: 2 }}
                >
                    <Container>
                        <Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: { xs: '24px', md: '42px' },
                                    lineHeight: { xs: '28px', md: '48px' },
                                    letterSpacing: '-0.5px',
                                    textAlign: 'center',
                                }}
                            >
                                Commonly Asked Questions
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            {FaqData.map((data, index) => {
                                return (
                                    <Accordion key={uuidv4()}>
                                        <AccordionSummary
                                            expandIcon={<ArrowDownwardIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <Typography>
                                                {data.question}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                {data.answer}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        </Box>
                    </Container>
                </Paper>
            </Box>
            <Box sx={{ mt: 5, textAlign: 'center' }}>
                <Paper
                    elevation={0}
                    sx={{ background: '#F6F0E9', p: 5, borderRadius: 10, m: 2 }}
                >
                    <Stack spacing={2}>
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 800,
                                fontSize: { xs: '28px', md: '42px' },
                                lineHeight: { xs: '32px', md: '48px' },
                                letterSpacing: '-0.5px',
                            }}
                        >
                            Still thinking?
                        </Typography>

                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 600,
                                fontSize: {
                                    xs: '20px',
                                    sm: '22px',
                                    md: '24px',
                                },
                                letterSpacing: '-0.5px',
                                fontStyle: 'italic',
                            }}
                        >
                            Let's hop on a short call and see how we can turn
                            your ideas into reality.
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#666666',
                            }}
                        >
                            We’re committed to helping startup founders get off
                            the ground.
                        </Typography>
                        <a
                            href="https://cal.com/leanmvp/15min"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <CustomButton text="Book a 15 min call" />
                        </a>
                    </Stack>
                </Paper>
            </Box>
        </div>
    );
};

export default FaqSection;
