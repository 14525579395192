import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Paper, Box, Typography, Container, Grid } from '@mui/material';

import HowWorksCard from '../../components/HowWorksCard';

import Feature1 from '../../assets/feature_1.svg';
import Feature2 from '../../assets/feature_2.svg';
import Feature3 from '../../assets/feature_3.svg';
import Feature4 from '../../assets/feature_4.svg';

const HowWorksData = [
    {
        imageUrl: Feature1,
        title: '1. Start with a Discovery Call',
        text: "Kick off your project with a simple chat. Drop us a line, and we'll set up a call to dig into your idea. It's all about understanding what makes your vision unique so we can craft a tailored plan just for you. No jargon, no fuss — just the first step to something great.",
    },
    {
        imageUrl: Feature2,
        title: '2. Crafting Your Development Blueprint',
        text: 'After our call, we’ll put together a plan to ensure we achieve what you need. Your feedback is key here - we will tweak it until you say it is perfect. Then, we’ll get to work bringing your idea to life, with you in the driver’s seat every step of the way.',
    },
    {
        imageUrl: Feature3,
        title: '3. Turning Plan Into Product',
        text: "With a plan in hand, our skilled team gets down to business. They're mixing their know-how with your vision to create a product your customers will want. We keep things lean and cost-effective, iterating and improving as we go, so you get the best possible outcome without the extra expense.",
    },
    {
        imageUrl: Feature4,
        title: '4. Launch In The Market',
        text: "Launching your product is a big milestone and we take the stress out of going live. It means you can launch confidently and start focusing on what's next: growing your business and wowing your customers.",
    },
];

const HowWorks = () => {
    return (
        <div className="how_works">
            <Box sx={{ mt: 5 }}>
                <Paper
                    elevation={0}
                    sx={{ background: '#EBE7F9', pt: 8, pb: 8 }}
                >
                    <Container>
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 700,
                                fontSize: {
                                    xs: '20px',
                                    sm: '22px',
                                    md: '24px',
                                },
                                lineHeight: '48px',
                                letterSpacing: '-0.5px',
                                textAlign: 'center',
                            }}
                        >
                            Our Proven Process
                        </Typography>
                        <Box sx={{ mt: 5 }}>
                            <Grid container spacing={4}>
                                {HowWorksData.map((data, index) => (
                                    <Grid item xs={12} md={6}>
                                        <HowWorksCard
                                            key={uuidv4()}
                                            {...data}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Container>
                </Paper>
            </Box>
        </div>
    );
};

export default HowWorks;
