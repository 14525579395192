import React from 'react';
import { Button, Box } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

interface ButtonProps {
    text: string;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    onClick?: (data?: any) => void;
    type?: 'button' | 'submit' | 'reset';
    showIcon?: boolean;
}

const CustomButton = ({ text, onClick, type, showIcon }: ButtonProps) => {
    return (
        <div className="button">
            <Box>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#4E46B4',
                        borderRadius: '10px',
                        '&:hover': {
                            backgroundColor: '#4E46B4',
                        },
                        textTransform: 'none',
                    }}
                    onClick={onClick}
                    type={type}
                >
                    {showIcon && <ArrowOutwardIcon />}
                    {text}
                </Button>
            </Box>
        </div>
    );
};

export default CustomButton;
