import React from 'react';

import { Paper, Stack, Box, Typography } from '@mui/material';

interface HowWorksCardProps {
    imageUrl: string;
    text: string;
    title: string;
}

const HowWorksCard = ({ text, title, imageUrl }: HowWorksCardProps) => {
    return (
        <div className="how_works_card">
            <Box>
                <Paper elevation={0} sx={{ background: '#EBE7F9' }}>
                    <Stack spacing={2} direction="row">
                        <img
                            src={imageUrl}
                            style={{ display: 'block', maxWidth: '100%' }}
                            alt={title}
                        />
                        <Stack spacing={1}>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 800,
                                    fontSize: {
                                        xs: '18px',
                                        sm: '20px',
                                        md: '22px',
                                        lg: '24px',
                                    },
                                    lineHeight: {
                                        xs: '24px',
                                        sm: '28px',
                                        md: '30px',
                                        lg: '32px',
                                    },
                                }}
                            >
                                {title}
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '26px',
                                }}
                            >
                                {text}
                            </Typography>
                        </Stack>
                    </Stack>
                </Paper>
            </Box>
        </div>
    );
};

export default HowWorksCard;
