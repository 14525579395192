import type React from 'react';
import {
    createContext,
    useContext,
    useRef,
    type ReactNode,
    type MutableRefObject,
} from 'react';

interface ScrollContextType {
    formSectionRef: MutableRefObject<HTMLDivElement | null>;
}

const ScrollContext = createContext<ScrollContextType | undefined>(undefined);

export const ScrollProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const formSectionRef = useRef<HTMLDivElement | null>(null);

    return (
        <ScrollContext.Provider value={{ formSectionRef }}>
            {children}
        </ScrollContext.Provider>
    );
};

export const useFormSectionScroll = (): ScrollContextType => {
    const context = useContext(ScrollContext);
    if (context === undefined) {
        throw new Error(
            'useFormSectionScroll must be used within a ScrollProvider'
        );
    }
    return context;
};
