import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

import { initializeTagManager } from './gtm';
import TagManager from 'react-gtm-module';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import HomePage from './pages/HomePage';
import Blog from './pages/Blogs';
import { ScrollProvider } from './ScrollContext';

import Hotjar from '@hotjar/browser';

const siteId = 5005303;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const TRACKING_ID = 'G-TWQ7P7C3QE';
ReactGA.initialize(TRACKING_ID);

function App() {
    useEffect(() => {
        initializeTagManager();
    }, []);

    ReactGA.send({
        hitType: 'Landing Page',
        page: '/',
        title: 'HomePage View',
    });

    // send custom event to GTM
    TagManager.dataLayer({
        dataLayer: {
            event: 'Landing Page View',
            category: 'Home Page',
            action: 'Landing Page View',
            label: 'LeanMVP Page',
        },
    });

    return (
        <ScrollProvider>
            <Router>
                <div className="App">
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route
                            path="/blogs/step-by-step-guide-to-successfully-launching-your-successful-product"
                            element={<Blog />}
                        />

                        {/*<Route path="/about" element={<AboutUs />} />*/}
                    </Routes>
                </div>
            </Router>
        </ScrollProvider>
    );
}

export default App;
