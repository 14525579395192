import React from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

import {
    Box,
    Container,
    Paper,
    Grid,
    Typography,
    Stack,
    Divider,
} from '@mui/material';

import CustomButton from '../../components/CustomButton';

import { useFormSectionScroll } from '../../ScrollContext';

import HeaderMain from '../../assets/header_main.svg';
import LeanMVP from '../../assets/LeanMVP.svg';

const Header = () => {
    const { formSectionRef } = useFormSectionScroll();

    const handleContactClick = () => {
        if (formSectionRef.current) {
            formSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        ReactGA.event({
            category: 'Contact Button',
            action: 'Contact button clicked',
            label: 'Header Contact Button',
        });
        TagManager.dataLayer({
            dataLayer: {
                event: 'Contact Button Clicked',
                category: 'Contact Button',
                action: 'Contact button clicked',
                label: 'Header Contact Button',
            },
        });
    };

    return (
        <div className="header">
            <Box>
                <Paper
                    elevation={0}
                    sx={{
                        background: '#FFF6E3',
                    }}
                >
                    <Box sx={{ textAlign: 'center', pt: 3 }}>
                        <Link to="/">
                            <img src={LeanMVP} alt="LeanMVP" />
                        </Link>
                    </Box>
                    <Divider sx={{ mt: 2, mr: 6, ml: 6 }} />
                    <Container>
                        <Box className="header-content" sx={{ mt: 8 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={7}>
                                    <Stack spacing={3}>
                                        <Typography
                                            variant="h2"
                                            gutterBottom
                                            sx={{
                                                fontWeight: 650,
                                                fontSize: {
                                                    xs: '30px',
                                                    md: '50px',
                                                },
                                                lineHeight: {
                                                    xs: '40px',
                                                    md: '64px',
                                                },
                                                letterSpacing: '-1.5px',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: '#4E46B4',
                                                }}
                                            >
                                                Build Your Products Without
                                                Spending Hours On Software
                                                Development Or Hiring a
                                                Full-time Developer
                                            </span>{' '}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: '#595D62',
                                            }}
                                        >
                                            Are you a startup founder with a
                                            great idea but unsure how to build
                                            it?
                                            <br />
                                            Perhaps you wish you could hire a
                                            full-time developer, but your budget
                                            won't allow it?
                                            <br />
                                            Where to start, and how to build a
                                            product roadmap that appeals to
                                            customers and investors alike?
                                            <br />
                                            <br />
                                            With LeanMVP, we do the technical
                                            work, so you don’t have to.
                                        </Typography>
                                    </Stack>
                                    <Box sx={{ mt: 5 }}>
                                        <CustomButton
                                            text="Get In Touch"
                                            onClick={handleContactClick}
                                            showIcon={true}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{ mt: 14 }}>
                                        <img
                                            src={HeaderMain}
                                            alt="Header Main"
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                maxWidth: '100%',
                                                maxHeight: '400px',
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Paper>
            </Box>
        </div>
    );
};

export default Header;
