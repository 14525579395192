import React from 'react';

import { Box, Paper, Stack, Typography } from '@mui/material';

interface ExpertiseCardProps {
    title: string;
    description: string;
}

const ExpertiseCard = ({ title, description }: ExpertiseCardProps) => {
    return (
        <div className="expertise-card">
            <Box>
                <Paper
                    elevation={0}
                    sx={{
                        padding: 2,
                        borderRadius: '30px',
                        background: '#D7D4FF',
                    }}
                >
                    <Stack spacing={2}>
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 800,
                                fontSize: {
                                    xs: '18px',
                                    sm: '20px',
                                    md: '22px',
                                    lg: '24px',
                                },
                                lineHeight: {
                                    xs: '24px',
                                    sm: '28px',
                                    md: '30px',
                                    lg: '32px',
                                },
                            }}
                        >
                            {title}
                        </Typography>

                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '26px',
                            }}
                        >
                            {description}
                        </Typography>
                    </Stack>
                </Paper>
            </Box>
        </div>
    );
};

export default ExpertiseCard;
