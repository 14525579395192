import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import CustomButton from './CustomButton';
import { useFormSectionScroll } from '../ScrollContext';

import LeanMVP from '../assets/LeanMVP.svg';

const pages = [{ page: 'Home', link: '/' }];

interface Props {
    showCTA?: boolean;
}

const Navbar = ({ showCTA = true }: Props) => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
        null
    );

    const { formSectionRef } = useFormSectionScroll();

    const handleContactClick = () => {
        if (formSectionRef.current) {
            formSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar
            position="static"
            sx={{ background: 'none', color: '#000000' }}
            elevation={0}
        >
            <Container>
                <Toolbar disableGutters>
                    <Link to="/">
                        <img src={LeanMVP} alt="LeanMVP" />
                    </Link>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box
                        sx={{
                            display: { xs: 'flex', md: 'none' },
                            justifyContent: 'flex-end',
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map(({ page, link }) =>
                                page === 'Contact Us' ? (
                                    <MenuItem
                                        key={uuidv4()}
                                        onClick={handleCloseNavMenu}
                                    >
                                        <a
                                            href="mailto:deepak@leanmvp.co"
                                            style={{
                                                textDecoration: 'none',
                                                color: 'inherit',
                                            }}
                                        >
                                            <Typography textAlign="center">
                                                {page}
                                            </Typography>
                                        </a>
                                    </MenuItem>
                                ) : (
                                    <MenuItem
                                        key={uuidv4()}
                                        onClick={handleCloseNavMenu}
                                    >
                                        <Link
                                            to={link}
                                            style={{
                                                textDecoration: 'none',
                                                color: 'inherit',
                                            }}
                                        >
                                            <Typography textAlign="center">
                                                {page}
                                            </Typography>
                                        </Link>
                                    </MenuItem>
                                )
                            )}
                        </Menu>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'flex' },
                        }}
                    >
                        {pages.map(({ page, link }) =>
                            page === 'Contact Us' ? (
                                <Button
                                    onClick={handleCloseNavMenu}
                                    sx={{
                                        color: '#000000',
                                        display: 'block',
                                        textTransform: 'none',
                                        marginRight: 1,
                                        marginLeft: 1,
                                    }}
                                >
                                    <a
                                        href="mailto:deepak@leanmvp.co"
                                        style={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                        }}
                                    >
                                        {page}
                                    </a>
                                </Button>
                            ) : (
                                <Link
                                    to={link}
                                    style={{
                                        textDecoration: 'none',
                                        color: 'inherit',
                                    }}
                                >
                                    <Button
                                        onClick={handleCloseNavMenu}
                                        sx={{
                                            color: '#000000',
                                            display: 'block',
                                            textTransform: 'none',
                                            marginRight: 1,
                                            marginLeft: 1,
                                        }}
                                    >
                                        {page}
                                    </Button>
                                </Link>
                            )
                        )}
                    </Box>
                    {showCTA && (
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <CustomButton
                                text="Get In Touch"
                                onClick={handleContactClick}
                            />
                        </Box>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default Navbar;
