import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Paper, Container, Typography, Stack, Box, Grid } from '@mui/material';
import ExpertiseCard from '../../components/ExpertiseCard';

const ExpertiseSectionData = [
    {
        title: 'Custom Software Development',
        description:
            "Got a cool project idea? Let’s bring it to life! Our team specializes in crafting custom software tailored precisely to your specifications and your users' needs. From start to finish, we make sure your software is something special.",
    },
    {
        title: 'Mobile App Development',
        description:
            'Your app should stand out, not just fit in. Our custom mobile apps are not only visually appealing but also perform flawlessly. No matter how big your dream is, we are equipped to bring it to life with cutting-edge tech and a focus on the user experience. Let’s create an app for everyone to download and love.',
    },
    {
        title: 'DevOps Process',
        description:
            "Looking to deploy your software quickly without sacrificing quality? Our DevOps team is here to make sure you can. We streamline your software's release process, maintaining top-notch quality while ensuring a speedy journey to market. Let’s stay ahead of the curve together.",
    },
];

const ExpertiseSection = () => {
    return (
        <div className="expertise-section">
            <Box sx={{ mt: 5 }}>
                <Paper elevation={0}>
                    <Container>
                        <Stack spacing={1} alignItems="center">
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: {
                                        xs: '20px',
                                        sm: '22px',
                                        md: '24px',
                                    },
                                    lineHeight: '48px',
                                    letterSpacing: '-0.5px',
                                }}
                            >
                                Our Expertise
                            </Typography>
                        </Stack>
                        <Box sx={{ mt: 5, justifyContent: 'center' }}>
                            <Grid
                                container
                                spacing={2}
                                justifyContent="flex-start"
                            >
                                {ExpertiseSectionData.map((data) => (
                                    <Grid item xs={12} sm={4} md={4}>
                                        <ExpertiseCard
                                            key={uuidv4()}
                                            title={data.title}
                                            description={data.description}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Container>
                </Paper>
            </Box>
        </div>
    );
};

export default ExpertiseSection;
