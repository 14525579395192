import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

import { useForm, Controller } from 'react-hook-form';
import {
    TextField,
    Stack,
    Box,
    Paper,
    Container,
    Typography,
    FormGroup,
    Checkbox,
    Grid,
    FormControlLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CustomButton from '../../components/CustomButton';

import FormSuccess from './FormSuccess';

const PROJECT_BACKEND_URL = process.env.REACT_APP_BACKEND_URL as string;
const BACKEND_KEY = process.env.REACT_APP_API_KEY as string;
const API_ENDPOINT = `${PROJECT_BACKEND_URL}/project`;

const quoteDescription = [
    {
        text: "Challenges with Your Current Project: We'll talk about any issues you're facing with your project and how we can solve them.",
    },
    {
        text: 'Product Development Advice: Get advice tailored to what you need for your project, to help start or grow your business.',
    },
    {
        text: "Estimated Project Cost: Receive an initial cost estimate tailored to your project's needs.",
    },
    {
        text: 'Your Questions Answered: Have questions? We’re here to provide the answers you need to move forward confidently.',
    },
];
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const FormSection = ({ forwardedRef }: any) => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formError, setFormError] = useState(false);

    const registerOptions = {
        fullName: { required: 'Full name is required' },
        companyName: { required: 'Company name is required' },
        businessEmail: {
            required: 'Business email is required',
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
            },
        },
        projectDetails: {
            required: 'Project details are required',
            minLength: {
                value: 200,
                message: 'Project details must be at least 200 words',
            },
        },
        countryName: { required: 'Country is required' },
        projectBudget: { required: 'Project budget is required' },
        projectServices: { required: 'Project services are required' },
        projectStartDate: { required: 'Project start date is required' },
    };

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const handleRegistration = async (data: any) => {
        const request_data = {
            full_name: data.fullName,
            company_name: data.companyName,
            company_email: data.businessEmail,
            project_details: data.projectDetails,
            country: data.countryName,
            project_budget: data.projectBudget,
            project_services: data.projectServices,
            project_start_date: data.projectStartDate,
            project_require_nda: data.requireNDA || false,
        };
        console.log(request_data);

        try {
            const response = await fetch(API_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization-Token': BACKEND_KEY,
                },
                body: JSON.stringify(request_data),
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error response:', errorText);
                throw new Error(
                    `Request failed with status ${response.status}`
                );
            }

            const responseData = await response.json();

            if (responseData.message === 'Project query saved successfully') {
                console.log('Success:', responseData);
                setFormSubmitted(true);
                ReactGA.event({
                    category: 'Form Button',
                    action: 'Query Form Submitted',
                    label: 'Get In Touch',
                });
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'Form Button',
                        category: 'Form Button',
                        action: 'Query Form Submitted',
                        label: 'Get In Touch',
                    },
                });
            } else {
                console.log('Error:', responseData);
                throw new Error('Something went wrong');
            }
        } catch (error) {
            console.log('Error', error);
            setFormError(true);
        }
    };

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const handleError = (errors: any) => {};

    return (
        <form onSubmit={handleSubmit(handleRegistration, handleError)}>
            <Box ref={forwardedRef} sx={{ mt: 5 }}>
                <Paper
                    elevation={0}
                    sx={{ background: '#F6F0E9', p: 5, borderRadius: 10, m: 2 }}
                >
                    <Container>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: { xs: '24px', md: '36px' },
                                        lineHeight: { xs: '32px', md: '48px' },
                                        letterSpacing: '-0.5px',
                                    }}
                                >
                                    Ready to Turn Your Vision Into Reality?
                                </Typography>

                                <Stack spacing={2} sx={{ mt: 3 }}>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '20px',
                                            letterSpacing: '-0.5px',
                                            lineHeight: '28px',
                                        }}
                                    >
                                        Connect with us today to discuss how we
                                        can help bring your project to life with
                                        precision and passion. Fill out the form
                                        to arrange a chat, and let's start the
                                        journey together.
                                    </Typography>
                                </Stack>
                                <Stack spacing={2} sx={{ mt: 3 }}>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: '20px',
                                            letterSpacing: '-0.5px',
                                            lineHeight: '25px',
                                        }}
                                    >
                                        In your personalized call, we will talk
                                        about:
                                    </Typography>
                                    {quoteDescription.map(
                                        (description, index) => (
                                            <React.Fragment key={index}>
                                                <Box sx={{ mt: 5 }}>
                                                    <Stack
                                                        direction="row"
                                                        spacing={1}
                                                    >
                                                        <CheckCircleIcon
                                                            sx={{
                                                                color: '#009E73',
                                                            }}
                                                        />
                                                        <Typography variant="body1">
                                                            {description.text}
                                                        </Typography>
                                                    </Stack>
                                                </Box>
                                            </React.Fragment>
                                        )
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                {formSubmitted && !formError && <FormSuccess />}
                                {!formSubmitted && (
                                    <>
                                        {formError && (
                                            <Box sx={{ mt: 7 }}>
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        color: 'red',
                                                    }}
                                                >
                                                    We encountered a problem
                                                    processing your form. Please
                                                    attempt to submit it again.
                                                    If the problem persists,
                                                    we're here to help. Reach
                                                    out to us at
                                                    deepak@leanmvp.co.
                                                </Typography>
                                            </Box>
                                        )}

                                        <Box
                                            sx={{
                                                mt: 5,
                                            }}
                                        >
                                            <Box sx={{ mb: 3 }}>
                                                <Typography
                                                    variant="h4"
                                                    sx={{
                                                        fontWeight: 700,
                                                        fontSize: '24px',
                                                        lineHeight: '48px',
                                                        letterSpacing: '-0.5px',
                                                    }}
                                                >
                                                    Get Free Quote
                                                </Typography>
                                            </Box>
                                            <Stack spacing={4}>
                                                <TextField
                                                    {...register(
                                                        'fullName',
                                                        registerOptions.fullName
                                                    )}
                                                    label="Full Name"
                                                    error={!!errors.fullName}
                                                    helperText={
                                                        errors?.fullName
                                                            ?.message as string
                                                    }
                                                />
                                                <TextField
                                                    {...register(
                                                        'countryName',
                                                        registerOptions.countryName
                                                    )}
                                                    label="Country"
                                                    error={!!errors.countryName}
                                                    helperText={
                                                        errors?.countryName
                                                            ?.message as string
                                                    }
                                                />
                                                <TextField
                                                    {...register(
                                                        'companyName',
                                                        registerOptions.companyName
                                                    )}
                                                    label="Company Name"
                                                    error={!!errors.companyName}
                                                    helperText={
                                                        errors?.companyName
                                                            ?.message as string
                                                    }
                                                />
                                                <TextField
                                                    {...register(
                                                        'businessEmail',
                                                        registerOptions.businessEmail
                                                    )}
                                                    label="Business Email"
                                                    error={
                                                        !!errors.businessEmail
                                                    }
                                                    helperText={
                                                        errors?.businessEmail
                                                            ?.message as string
                                                    }
                                                />
                                                <TextField
                                                    {...register(
                                                        'projectDetails',
                                                        registerOptions.projectDetails
                                                    )}
                                                    label="Project Details"
                                                    multiline
                                                    rows={4}
                                                    error={
                                                        !!errors.projectDetails
                                                    }
                                                    helperText={
                                                        errors?.projectDetails
                                                            ?.message as string
                                                    }
                                                />
                                                <FormControl>
                                                    <InputLabel>
                                                        Budget
                                                    </InputLabel>
                                                    <Select
                                                        {...register(
                                                            'projectBudget',
                                                            registerOptions.projectBudget
                                                        )}
                                                        label="Budget"
                                                        error={
                                                            !!errors.projectBudget
                                                        }
                                                    >
                                                        <MenuItem value="10k-20k">
                                                            $10k-$20k
                                                        </MenuItem>
                                                        <MenuItem value="20k-50k">
                                                            $20k-$50k
                                                        </MenuItem>
                                                        <MenuItem value="above-50k">
                                                            $50k-$100k
                                                        </MenuItem>
                                                        <MenuItem value="above-50k">
                                                            Above $100k
                                                        </MenuItem>
                                                    </Select>
                                                    {errors.projectBudget && (
                                                        <Typography
                                                            variant="caption"
                                                            color="error"
                                                            sx={{
                                                                marginLeft: 2,
                                                            }}
                                                        >
                                                            {
                                                                errors
                                                                    .projectBudget
                                                                    .message as string
                                                            }
                                                        </Typography>
                                                    )}
                                                </FormControl>
                                                <FormControl>
                                                    <Controller
                                                        name="projectServices"
                                                        control={control}
                                                        defaultValue={[]}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                            },
                                                        }) => (
                                                            <Autocomplete
                                                                multiple
                                                                options={[
                                                                    'Product Design',
                                                                    'Full Stack Development',
                                                                    'Mobile App Development',
                                                                    'AI Development',
                                                                    'DevOps',
                                                                ]}
                                                                disableCloseOnSelect
                                                                getOptionLabel={(
                                                                    option
                                                                ) => option}
                                                                renderOption={(
                                                                    props,
                                                                    option,
                                                                    { selected }
                                                                ) => (
                                                                    <li
                                                                        {...props}
                                                                    >
                                                                        <Checkbox
                                                                            style={{
                                                                                marginRight: 8,
                                                                            }}
                                                                            checked={
                                                                                selected
                                                                            }
                                                                        />
                                                                        {option}
                                                                    </li>
                                                                )}
                                                                value={value}
                                                                onChange={(
                                                                    event,
                                                                    newValue
                                                                ) => {
                                                                    onChange(
                                                                        newValue
                                                                    );
                                                                }}
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Services (Select all that apply)"
                                                                        error={
                                                                            !!errors.projectServices
                                                                        }
                                                                        helperText={
                                                                            errors
                                                                                ?.projectServices
                                                                                ?.message as string
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                        rules={{
                                                            required:
                                                                'Please select at least one service.',
                                                        }}
                                                    />
                                                </FormControl>

                                                <FormControl>
                                                    <InputLabel>
                                                        Start Date
                                                    </InputLabel>
                                                    <Select
                                                        {...register(
                                                            'projectStartDate',
                                                            registerOptions.projectStartDate
                                                        )}
                                                        label="Start Date"
                                                        error={
                                                            !!errors.projectStartDate
                                                        }
                                                    >
                                                        <MenuItem value="immediately">
                                                            Start Immediately
                                                        </MenuItem>
                                                        <MenuItem value="month">
                                                            Start in a month
                                                        </MenuItem>
                                                        <MenuItem value="exploratory">
                                                            Just Exploratory
                                                        </MenuItem>
                                                    </Select>
                                                    {errors.projectStartDate && (
                                                        <Typography
                                                            variant="caption"
                                                            color="error"
                                                            sx={{
                                                                marginLeft: 2,
                                                            }}
                                                        >
                                                            {
                                                                errors
                                                                    .projectStartDate
                                                                    .message as string
                                                            }
                                                        </Typography>
                                                    )}
                                                </FormControl>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                {...register(
                                                                    'requireNDA'
                                                                )}
                                                            />
                                                        }
                                                        label="This project requires an NDA to be signed before discussions."
                                                    />
                                                    <FormControlLabel
                                                        required
                                                        control={<Checkbox />}
                                                        label="I agree to receive further communications from leanmvp.co."
                                                    />
                                                </FormGroup>
                                                <CustomButton
                                                    text="Submit"
                                                    type="submit"
                                                />
                                            </Stack>
                                        </Box>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
            </Box>
        </form>
    );
};

export default FormSection;
