import React from 'react';
import { Box, Paper, Container, Grid, Stack, Typography } from '@mui/material';
import WorkflowCard from '../../components/WorkflowCard';

import How1 from '../../assets/how1.svg';
import How2 from '../../assets/how2.svg';
import How3 from '../../assets/how3.svg';
import How5 from '../../assets/how5.svg';

const WorkflowData = [
    {
        image: How1,
        title: 'Idea Discovery',
        content: "Let's uncover the potential of your idea together.",
    },
    {
        image: How2,
        title: 'Market Validation',
        content: 'Ensure your product resonates with your target audience.',
    },
    {
        image: How3,
        title: 'Efficient Build',
        content: 'Develop your solutions efficiently in cost effective ways.',
    },
    {
        image: How5,
        title: 'Launch & Iterate',
        content:
            'Get your product in front of your customers and refine iteratively.',
    },
];

const Workflow = () => {
    return (
        <div className="workflow">
            <Box sx={{ mt: 10, mb: 10 }}>
                <Paper elevation={0}>
                    <Container sx={{ padding: { xs: 2, sm: 0 } }}>
                        {' '}
                        {/* Responsive padding */}
                        <Stack spacing={2}>
                            <Typography
                                sx={{
                                    fontWeight: 800,
                                    fontSize: {
                                        xs: '24px',
                                        md: '36px',
                                        lg: '42px',
                                    },
                                    lineHeight: {
                                        xs: '32px',
                                        md: '40px',
                                        lg: '48px',
                                    },
                                    letterSpacing: '-0.5px',
                                    textAlign: 'center',
                                }}
                            >
                                Streamline Your Product Development Journey
                            </Typography>

                            <Box
                                sx={{
                                    pt: 4,
                                    padding: {
                                        xs: 4,
                                        sm: 0,
                                    },
                                }}
                            >
                                <Grid
                                    container
                                    spacing={{ xs: 6, md: 8 }}
                                    justifyContent="center"
                                >
                                    {WorkflowData.map((item) => (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            lg={3}
                                            key={item.title}
                                        >
                                            <WorkflowCard
                                                image={item.image}
                                                title={item.title}
                                                content={item.content}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Stack>
                    </Container>
                </Paper>
            </Box>
        </div>
    );
};

export default Workflow;
