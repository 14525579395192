import React from 'react';
import { Helmet } from 'react-helmet';

import FirstBlog from './FirstBlog.mdx';
import { Container, Paper } from '@mui/material';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const Blog = () => {
    return (
        <div>
            <Helmet>
                <title>
                    LeanMVP | A Step-by-Step Guide to Successfully Launching
                    Your First Product
                </title>
                <meta
                    name="description"
                    content="Unlock startup success step-by-step with LeanMVP! From idea validation to product launch, master MVP development. Dive in now!"
                />
            </Helmet>
            <Paper
                sx={{
                    background: '#F6F0E9',
                }}
                elevation={0}
            >
                <Navbar showCTA={false} />
            </Paper>
            <Container>
                <FirstBlog />
            </Container>
            <Footer />
        </div>
    );
};

export default Blog;
