import React from 'react';
import ReactGA from 'react-ga4';

import { Paper, Box, Typography, Grid, Stack, Avatar } from '@mui/material';

interface BlogContentCardProps {
    blogTitle: string;
    subText: string;
    coverImg: string;
    imgCredits: string;
    authorName: string;
    authorTitle: string;
    authorImg: string;
    blogDate: string;
    readingTime: string;
}

const BlogContentCard = ({
    blogTitle,
    subText,
    coverImg,
    authorName,
    authorImg,
    authorTitle,
    blogDate,
    readingTime,
    imgCredits,
}: BlogContentCardProps) => {
    ReactGA.send({
        hitType: 'Blogs Page',
        page: '/blogs/guide-to-launching-a-successful-product',
        title: 'Blog Page View',
    });

    return (
        <div>
            <Box sx={{ margin: 2, padding: 4 }}>
                <Paper elevation={0}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Stack spacing={3}>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontWeight: 800,
                                        fontSize: '36px',
                                        lineHeight: '48px',
                                    }}
                                >
                                    {blogTitle}
                                </Typography>
                                <Typography variant="body1">
                                    {subText}
                                </Typography>
                                <Stack direction="row" spacing={2}>
                                    <Typography
                                        variant="body1"
                                        sx={{ color: '#666666' }}
                                    >
                                        {blogDate}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{ color: '#666666' }}
                                    >
                                        {readingTime}
                                    </Typography>
                                </Stack>
                                <Stack direction="row" spacing={2}>
                                    <Avatar
                                        alt={authorName}
                                        src={authorImg}
                                        sx={{ width: 80, height: 80 }}
                                    />
                                    <Stack spacing={1}>
                                        <Typography variant="body1">
                                            {authorName}
                                        </Typography>
                                        <Typography variant="body1">
                                            {authorTitle}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Stack spacing={1}>
                                <img
                                    src={coverImg}
                                    alt="Blog Cover"
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        maxWidth: '120%',
                                        maxHeight: '450px',
                                    }}
                                />

                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#666666',
                                        textAlign: 'right',
                                    }}
                                >
                                    {imgCredits}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </div>
    );
};

export default BlogContentCard;
