import React from 'react';

import { Box, Paper, Typography, Stack, Container, Grid } from '@mui/material';
import TestimonialCard from '../../components/TestimonialCard';

import GeniusAvtar from '../../assets/genius_gate.png';

const testimonialsData = [
    {
        founderName: 'Alex',
        title: 'Founder',
        companyName: 'GeniusGate',
        companyUrl: 'https://geniusgate.ai/',
        imageSrc: GeniusAvtar,
        testimonialContent:
            "Deepak, the founder of LeanMVP, has played a key role in moving our project forward. I came across Deepak online and we decided to discuss my goals. Initially hesitant, I soon learned to trust Deepak and his capabilities. I was impressed with his skills, organization, and insightful suggestions. He always knew exactly what needed to be done and how to do it. His organizational skills and delivery quality are exceptional—he's always quick to respond and willing to hop on calls to get things right. Our software is still in development, but I will update this with further information soon.",
    },
];

const TestimonialSection = () => {
    return (
        <div className="testimonial_section">
            <Box sx={{ mt: 5 }}>
                <Paper
                    elevation={0}
                    sx={{ background: '#EBE7F9', p: 5, borderRadius: 3 }}
                >
                    <Container>
                        <Stack spacing={1} alignItems="center">
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: {
                                        xs: '20px',
                                        sm: '22px',
                                        md: '24px',
                                    },
                                    lineHeight: {
                                        xs: '32px',
                                        sm: '40px',
                                        md: '48px',
                                    },
                                    letterSpacing: '-0.5px',
                                }}
                            >
                                Hear What Founders Have to Say!{' '}
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    textAlign: {
                                        xs: 'center',
                                        sm: 'center',
                                        md: 'center',
                                    },
                                }}
                            >
                                Feedback from the founders who trusted us to
                                bring their ideas to life.
                            </Typography>
                        </Stack>
                        <Box sx={{ mt: 5 }}>
                            <Grid
                                container
                                spacing={4}
                                alignItems="center"
                                justifyContent="center"
                            >
                                {testimonialsData.map((data, index) => (
                                    <Grid item xs={12} md={6}>
                                        <TestimonialCard {...data} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Container>
                </Paper>
            </Box>
        </div>
    );
};

export default TestimonialSection;
