import React from 'react';

import { Box, Paper, Typography, Avatar, Stack } from '@mui/material';

interface TestimonialCardProps {
    founderName: string;
    companyName: string;
    companyUrl: string;
    title: string;
    imageSrc: string;
    testimonialContent: string;
}

const TestimonialCard = ({
    founderName,
    title,
    imageSrc,
    testimonialContent,
    companyUrl,
    companyName,
}: TestimonialCardProps) => {
    return (
        <div className="testimonial-card">
            <Box>
                <Paper
                    elevation={0}
                    sx={{
                        background: '#ffffff',
                        p: 5,
                        borderRadius: 3,
                        width: '80%',
                        margin: 'auto',
                    }}
                >
                    <Stack spacing={2}>
                        <Stack direction="row" spacing={2}>
                            <Avatar
                                src={imageSrc}
                                sx={{ width: 50, height: 50 }}
                                alt={`${founderName} Avatar`}
                            />
                            <Stack spacing={1}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: '20px',
                                        lineHeight: '24px',
                                    }}
                                >
                                    {founderName}
                                </Typography>
                                <Typography variant="body1">
                                    {`${title}@`}
                                    <a
                                        href={companyUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {companyName}
                                    </a>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#666666',
                            }}
                        >
                            {testimonialContent}
                        </Typography>
                    </Stack>
                </Paper>
            </Box>
        </div>
    );
};

export default TestimonialCard;
