import React from 'react';

//material ui imports
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
    return (
        <div className="footer">
            <Box sx={{ mt: 10 }}>
                <Paper
                    sx={{
                        background: '#071021',
                    }}
                >
                    <Grid container spacing={4} sx={{ p: 5 }}>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={4}>
                                <Stack direction="row" spacing={2}>
                                    <a
                                        href="https://www.linkedin.com/company/leanmvpco/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <LinkedInIcon
                                            sx={{ color: '#ffffff' }}
                                        />
                                    </a>
                                    <a
                                        href="https://docs.google.com/document/d/1FJ9IgS53TvdkxV5FEH-zXvjpTTpYXnzBeGitDn5Lj7k/edit?usp=sharing"
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{ color: '#ffffff', mt: -0.5 }}
                                        >
                                            | Terms and Conditions
                                        </Typography>
                                    </a>
                                    <a
                                        href="https://docs.google.com/document/d/1E2SF8UM_DLAoLOM5a2JFdwkzEz9BU3zewUA7Md5r7PM/edit?usp=sharing"
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{ color: '#ffffff', mt: -0.5 }}
                                        >
                                            | Privacy Policy
                                        </Typography>
                                    </a>
                                </Stack>
                                <Stack>
                                    <Typography
                                        variant="h6"
                                        sx={{ color: '#ffffff' }}
                                    >
                                        © 2024 LeanMVP | All rights reserved
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </div>
    );
};

export default Footer;
