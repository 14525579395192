import React from 'react';

import { Stack, Box, Paper, Typography } from '@mui/material';

interface HowWorksCardProps {
    image?: string;
    title: string;
    description: string;
}

const ServicesCard = ({ image, title, description }: HowWorksCardProps) => {
    return (
        <div className="services-card">
            <Box>
                <Paper elevation={0} sx={{ padding: 2, borderRadius: '30px' }}>
                    <Stack spacing={3}>
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 700,
                                fontSize: {
                                    xs: '18px',
                                    sm: '20px',
                                    md: '22px',
                                    lg: '24px',
                                },
                                lineHeight: {
                                    xs: '24px',
                                    sm: '28px',
                                    md: '30px',
                                    lg: '32px',
                                },
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {title}
                        </Typography>
                        <Typography variant="body1">{description}</Typography>
                    </Stack>
                </Paper>
            </Box>
        </div>
    );
};

export default ServicesCard;
