import React from 'react';
import { Stack, Box, Paper, Typography } from '@mui/material';

interface WorkflowCardProps {
    image: string;
    title: string;
    content: string;
}

const WorkflowCard = ({ image, title, content }: WorkflowCardProps) => {
    return (
        <div className="workflow-card">
            <Box>
                <Paper
                    elevation={0}
                    sx={{
                        padding: 2,
                        borderRadius: '30px',
                        border: '0.5px solid #000000',
                        width: '100%', // Use 100% width for better responsiveness
                    }}
                >
                    <Stack spacing={1} alignItems="center">
                        <img
                            src={image}
                            style={{
                                width: '100%',
                                height: 'auto',
                                maxWidth: '100%',
                                maxHeight: '150px',
                            }}
                            alt="workflow-card"
                        />
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 400,
                                fontSize: '24px',
                                lineHeight: '32px',
                                textAlign: 'center',
                                wordBreak: 'break-word',
                            }}
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '22px',
                                textAlign: 'center',
                                color: '#595D62',
                                textWrap: 'break-word',
                            }}
                        >
                            {content}
                        </Typography>
                    </Stack>
                </Paper>
            </Box>
        </div>
    );
};

export default WorkflowCard;
