import React from 'react';

import { Container, Paper, Typography, Stack, Box } from '@mui/material';
import CustomButton from '../../components/CustomButton';

const FormSuccess = () => {
    return (
        <div className="form-success">
            <Box sx={{ mt: 5 }}>
                <Paper elevation={0} sx={{ background: '#F6F0E9', p: 2 }}>
                    <Container>
                        <Stack spacing={2}>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '22px',
                                    lineHeight: '48px',
                                    letterSpacing: '-0.5px',
                                    color: '#4E46B4',
                                }}
                            >
                                Your query has been successfully submitted! 🎉
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '26px',
                                }}
                            >
                                Thank you for your interest in our services! Our
                                team is currently reviewing your query and will
                                respond shortly. For instant help, feel free to
                                book a direct call with us at your convenience.
                            </Typography>
                            <a
                                href="https://cal.com/leanmvp/30min"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <CustomButton text="Book a Call" />
                            </a>
                        </Stack>
                    </Container>
                </Paper>
            </Box>
        </div>
    );
};

export default FormSuccess;
