import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Box, Paper, Typography, Stack, Container } from '@mui/material';

import DiamondIcon from '@mui/icons-material/Diamond';

const aboutSectionContent = [
    {
        questionTitle: 'So who are we?',
        content:
            'At LeanMVP we’re your development team. We partner with you to help build your web application, mobile apps and custom software that generate profit so you can focus on other areas of your business.',
    },
    {
        questionTitle: 'But why LeanMVP?',
        content:
            'We serve early-stage start-ups and non-technical founders who want a tech partner. However, a full-time tech expert or development team comes with high costs and high risk. Using LeanMVP as your partner eliminates all risk! All of our team are industry experts with years of development experience.',
    },
    {
        questionTitle: 'What do you get working with us?',
        content:
            'Well, you get a team of industry experts. A team that’s there for you, that’s ready to do the heavy lifting for you so you can relax in the knowledge that your product development is in capable hands. What will life look like? No more long projects, high fees, and missed deadlines. And no more trying to find and hire a full-time development team. You’ve got us.',
    },
];

const AboutSection = () => {
    return (
        <div>
            <Box sx={{ mt: 5 }}>
                <Paper elevation={0} sx={{ background: '#F6F0E9', p: 4 }}>
                    <Container>
                        <Stack spacing={3}>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '24px',
                                    lineHeight: '48px',
                                    letterSpacing: '-0.5px',
                                    color: '#595D62',
                                }}
                            >
                                About Us
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: {
                                        xs: '32px',
                                        md: '48px',
                                    },
                                    lineHeight: {
                                        xs: '36px',
                                        md: '50px',
                                    },
                                    letterSpacing: '-1.5px',
                                    fontWeight: 700,
                                }}
                            >
                                We're LeanMVP,
                                <br /> the Tech Partner for Founders.
                            </Typography>
                            <Box sx={{ mt: 2 }}>
                                {aboutSectionContent.map(
                                    ({ questionTitle, content }, index) => (
                                        <>
                                            <Box sx={{ pt: 4 }}>
                                                <Stack
                                                    spacing={2}
                                                    key={uuidv4()}
                                                >
                                                    <Stack
                                                        direction="row"
                                                        spacing={1}
                                                    >
                                                        <Box sx={{ pt: 1 }}>
                                                            <DiamondIcon
                                                                sx={{
                                                                    fontSize:
                                                                        '36px',
                                                                }}
                                                            />
                                                        </Box>
                                                        <Stack
                                                            spacing={1}
                                                            sx={{
                                                                width: '70%',
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h4"
                                                                sx={{
                                                                    fontWeight: 500,
                                                                    fontSize:
                                                                        '24px',
                                                                    lineHeight:
                                                                        '32px',
                                                                    letterSpacing:
                                                                        '-0.5px',
                                                                    color: '#595D62',
                                                                }}
                                                            >
                                                                {questionTitle}
                                                            </Typography>
                                                            <Typography variant="body1">
                                                                {content}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        </>
                                    )
                                )}
                            </Box>
                        </Stack>
                    </Container>
                </Paper>
            </Box>
        </div>
    );
};

export default AboutSection;
