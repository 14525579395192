import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Box, Paper, Grid, Container, Typography, Stack } from '@mui/material';
import ServicesCard from '../../components/ServiceCard';

const ServicesData = [
    {
        title: 'MVP Development',
        description:
            "Got a great idea? Let us turn it into a real product. Our team handles the tough parts, making it quick and easy to build your first product. You dream it, we make it happen. Let's bring your vision to life together.",
    },
    {
        title: 'Product Extension',
        description:
            'Looking to enhance your product with new features? We’ve got your back. We will not only improve your product but get it in front of more people too. We help you figure out the best ways to scale your product. Let’s grow together.',
    },
    {
        title: 'Delivery Process',
        description:
            'We’re all about getting your product to your customers smoothly, with no hassle. Our dedicated team makes sure everything works right, first time. Ensuring a smooth journey from our hands to yours.',
    },
    {
        title: 'Custom Help',
        description:
            "Do you need specialized help with your product? Whether it's tackling specific product-related challenges, optimizing your processes, or refining your DevOps strategies, our experts are equipped to provide bespoke solutions. Let us ensure your project achieves its full potential.",
    },
];

const Services = () => {
    return (
        <div className="services">
            <Box>
                <Paper
                    elevation={0}
                    sx={{ background: '#F2FDFA', padding: { xs: 3, sm: 10 } }}
                >
                    <Container maxWidth="xl">
                        <Stack spacing={1} alignItems="center">
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: {
                                        xs: '20px',
                                        sm: '22px',
                                        md: '24px',
                                    },
                                    lineHeight: {
                                        xs: '32px',
                                        sm: '40px',
                                        md: '48px',
                                    },
                                    letterSpacing: '-0.5px',
                                }}
                            >
                                Customised Development Services
                            </Typography>

                            <Typography variant="body1" noWrap>
                                Your Vision, Our Expertise
                            </Typography>
                        </Stack>
                        <Grid container spacing={4} sx={{ mt: 5 }}>
                            {ServicesData.map((item, index) => (
                                <Grid item xs={12} sm={6} md={6} lg={3}>
                                    <ServicesCard
                                        key={uuidv4()}
                                        title={item.title}
                                        description={item.description}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </Paper>
            </Box>
        </div>
    );
};

export default Services;
