import React from 'react';

import { Paper, Typography, Box, Stack } from '@mui/material';
import CustomButton from '../../components/CustomButton';

const BlogSection = () => {
    return (
        <div>
            <Box sx={{ mt: 5, textAlign: 'center' }}>
                <Paper
                    elevation={0}
                    sx={{ background: '#F6F0E9', p: 5, borderRadius: 10, m: 2 }}
                >
                    <Stack spacing={2}>
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 800,
                                fontSize: { xs: '24px', md: '42px' },
                                lineHeight: { xs: '32px', md: '48px' },
                                letterSpacing: '-0.5px',
                            }}
                        >
                            Read our guide to launch a successful product
                        </Typography>

                        <CustomButton
                            text="Read Now"
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href =
                                    '/blogs/step-by-step-guide-to-successfully-launching-your-successful-product';
                            }}
                        />
                    </Stack>
                </Paper>
            </Box>
        </div>
    );
};

export default BlogSection;
